import customerModule from '../../services/customer';
import { getAllStaticSegments } from '../../services/staticSegment';
import _ from 'lodash';

export default {
  name: 'customersUpload',
  data() {
    return {
      currentSection: 'fileSelect',

      // file select section
      inputForm: {
        csvFile: null,
        purposeOfUpload: 'none',
        selectedStaticSegment: null,
        newStaticSegmentName: null
      },
      validatingCSVFile: false,
      staticListErrorMsg: null,
      staticSegmentList: [],

      // Column Mapping Attributes
      columnList: [],
      dataTypeList: [
        {
          name: 'Text',
          value: 'string'
        },
        {
          name: 'Number',
          value: 'decimal'
        },
        {
          name: 'Date (Format: YYYY-MM-DD HH:MM:SS)',
          value: 'date'
        },
        {
          name: 'True/False',
          value: 'boolean'
        }
      ],
      customerMetadata: null,
      validationErrorList: []
    };
  },
  methods: {
    //#region **************** Start: File Select Section *************************

    addAttachment(data) {
      this.inputForm.csvFile = data.file;
    },

    async onFormSubmit() {
      try {
        // Validate input
        if (!this.inputForm.csvFile) {
          this.errorToast(`Please select csv file to upload.`);
          return;
        } else if (this.inputForm.purposeOfUpload == 'existing_list' && !this.inputForm.selectedStaticSegment) {
          this.staticListErrorMsg = 'Please select static list.';
          return;
        } else if (this.inputForm.purposeOfUpload == 'new_list' && (!this.inputForm.newStaticSegmentName || this.inputForm.newStaticSegmentName.trim() == '')) {
          this.staticListErrorMsg = 'Please specify new static list name.';
          return;
        } else if (this.inputForm.purposeOfUpload == 'new_list' && this.inputForm.newStaticSegmentName.length > 100) {
          this.staticListErrorMsg = 'Static list name can not exceed 100 chracters.';
          return;
        }

        let formData = new FormData();
        formData.append('file', this.inputForm.csvFile);
        this.validatingCSVFile = true;
        let result = await customerModule.validateCSVFile(formData, this);
        if (result.data.success) {
          this.columnIndex = 1;
          // Fill column map with numbers
          this.columnList = [];
          for (let i = 0; i < result.data.data.length; i++) {
            this.columnList.push({
              name: null,
              dataType: null,
              columnName: null,
              valueList: result.data.data[i],
              isNew: false,
              error: {
                status: false,
                message: null
              }
            });
          }

          this.currentSection = 'columnsMap';
        } else {
          this.errorToast('Upload failed. Please contact support.');
        }
        this.validatingCSVFile = false;
      } catch (e) {
        this.reportError(e);
        this.validatingCSVFile = false;
        if (e.status === 422 && e.data && e.data.message) {
          // Error Message
          this.errorToast(e.data.message);
          return;
        }
        this.errorToast(`Something went wrong`);
      }
    },

    //#endregion **************** End: File Select Section *************************

    //#region **************** Start: Column Map Section *************************

    onColumnMapChange(selectedCol) {
      // Set meta for selected column name.
      let propMetadata = _.find(this.customerMetadata, (filter) => {
        return filter.columnName == selectedCol.columnName;
      });
      selectedCol.name = propMetadata.name;
      selectedCol.columnName = propMetadata.columnName;
      selectedCol.dataType = propMetadata.type;
    },

    async onStartUpload() {
      try {
        // Validate column map.
        let cache = {};
        let isValid = true;
        let systemIdColumn = false;
        for (let i = 0; i < this.columnList.length; i++) {
          let col = this.columnList[i];
          if (!col.columnName) {
            col.error.status = 'required';
            col.error.message = 'Please select customer attribute to map.';
            isValid = false;
          } else if (cache[col.columnName]) {
            col.error.status = 'duplicate';
            col.error.message = `${col.name} is already mapped to another column.`;
            isValid = false;
          } else {
            col.error.status = null;
            col.error.message = null;
          }
          cache[col.columnName] = 1;

          if (col.columnName == 'system_id') systemIdColumn = true;
        }

        if (!isValid) {
          this.errorToast('Please provide valid column mapping for each csv column.', 6000);
          return;
        }

        // System ID Must Be Present
        if (!systemIdColumn) {
          this.errorToast('System Id column must be present to avoid duplicate customers.', 6000);
          return;
        }

        // Report event.
        this.marketingTrackEvent('CSV Upload', {
          'Company Id': this.$store.state.token.companyId,
          'Company Name': this.$store.state.token.companyName,
          'User Name': this.$store.state.token.name,
          'User Email': this.$store.state.token.email,
          'Platform Type': this.$store.state.token.companyPlatformType
        });

        // Prep Form Data
        let formData = new FormData();
        formData.append('file', this.inputForm.csvFile);
        formData.append('columnListToMap', JSON.stringify(this.columnList));
        formData.append('purposeOfUpload', this.inputForm.purposeOfUpload);
        if (this.inputForm.purposeOfUpload == 'existing_list') {
          formData.append('selectedStaticSegment', this.inputForm.selectedStaticSegment);
        }
        formData.append('newStaticSegmentName', this.inputForm.newStaticSegmentName);

        const loaderDialog = this.$loading({
          lock: true,
          text: "Please don't refresh browser.",
          spinner: 'el-icon-loading'
        });

        try {
          let result = await customerModule.uploadCustomersV2(formData, this);

          // Redirect to upload. List Page
          if (result.data.success) {
            this.$router.push('/customer/uploads');

            this.$swal({
              title: 'Upload Started',
              text: 'File upload has been started. It will take a few minutes to finish.',
              type: 'success',
              showCancelButton: false,
              confirmButtonColor: '#67C23A',
              confirmButtonText: 'Okay',
              allowEscapeKey: false,
              allowOutsideClick: false
            });
          } else {
            this.errorToast('Failed to upload. Please contact support.');
          }
        } catch (e) {
          if (e.status === 422 && e.data && e.data.errors) {
            // Set data type validation errors if theirs any
            this.validationErrorList = e.data.errors;
            return;
          }
          this.errorToast('Failed to upload. Please contact support.');
          this.reportError(e);
        } finally {
          loaderDialog.close();
        }
      } catch (err) {
        console.error('Upload failed:', err);
      }
    },

    //#region **************** End: Column Map Section *************************

    //#region **************** End: Other Methods Section Section *************************

    fetchCustomerMetadata() {
      customerModule
        .fetchUserPropertyList(
          {
            onlyCustomProps: true,
            formatDataTypeDisplayFext: true
          },
          this
        )
        .then((result) => {
          if (result.data.success) {
            this.customerMetadata = result.data.data;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async fetchStaticSegments() {
      try {
        this.staticSegmentList = (await getAllStaticSegments(false)).data;
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.fetchCustomerMetadata();
    this.fetchStaticSegments();
  }
};
